import type { FormikHelpers, FormikValues } from 'formik';
import Form from '~/components/forms/Form';
import useFingerprint from '~/hooks/useFingerprint';
import type { FormInput } from '~/types/inputs';
import { getApiURL } from '~/utils/api';
import { getPostSubmissionRedirection, handleError } from '~/utils/form';
import { stringifyUrlParameters } from '~/utils/string';

export default function Enter({
	inputs,
	buttonLabel,
	privacyPolicyRedirect,
	termsRedirect,
	minimumAge,
}: {
	inputs: FormInput[];
	buttonLabel: string;
	privacyPolicyRedirect: string;
	termsRedirect: string;
	minimumAge: number;
}) {
	const { getVisitorId } = useFingerprint();

	const handleSubmit = async (
		values: FormikValues,
		recaptcha: string,
		setErrors: FormikHelpers<FormikValues>['setErrors'],
	) => {
		const { visitorId, requestId } = await getVisitorId();
		const response = await fetch(`${getApiURL()}/forms/entry`, {
			method: 'POST',
			body: JSON.stringify({
				values: {
					...values,
					recaptcha,
					mobile: values.mobile ? values.mobile.replace(/[\s-]/g, '') : undefined,
				},
				meta: {
					fingerprintId: visitorId,
					requestId,
				},
			}),
			headers: {
				'Content-Type': 'application/json',
			},
		});

		const data = await response.json();

		if (response.ok) {
			window.location.href = `${getPostSubmissionRedirection(data)}?${stringifyUrlParameters({
				...data.data,
				mobile: data.data.requiresVerification ? values.mobile : undefined,
			})}`;
		} else {
			const entryLimitMessage = data?.error?.errors?.entry_limit_reached;

			if (typeof entryLimitMessage === 'string') {
				return window.location.assign(`/entry-limit-reached?message=${entryLimitMessage}`);
			}

			await Promise.reject(handleError(data, setErrors));
		}
	};

	return (
		<Form
			inputs={inputs}
			onSubmit={handleSubmit}
			privacyPolicyRedirect={privacyPolicyRedirect}
			termsRedirect={termsRedirect}
			buttonChildren={buttonLabel}
			minimumAge={minimumAge}
		/>
	);
}
